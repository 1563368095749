import { toJS } from 'mobx';
import { useState } from 'react';
import { CONTENT_TYPE } from './Component';

const useInputChange = () => {
  const [popType, setpopType] = useState(CONTENT_TYPE.DEFAULT)
  const [filterSearchList, setfilterSearchList] = useState('')
  const [text, settext] = useState('')

  const onInputChange = (e, marketSymbols, marketCoins) => {
    const text = e.target.value
    settext(text)
    const marketSymbolsTypeList = marketSymbols.map((listItem) => {
      if (listItem.coinCode.split('_')[1] === 'USD' || listItem.coinCode.split('_')[1] === 'HKD') {
        listItem.type = 'fiat';
      } else {
        listItem.type = 'spot';
      }
      return toJS(listItem);
    });
    const matchList = [...marketSymbolsTypeList]
      .filter((marketItem, index) => marketItem.coinCode.replace('_', '/').includes(text) || marketItem.coinCode.replace('_', '/').includes((text || '').toUpperCase()));
    const selectData = matchList.map(item => ({
      ...item,
      symbol: item.coinCode,
      logo: marketCoins.find(i => i.coinCode === item?.coinCode?.split('_')[0])?.picturePath
    }))
    if (selectData.length > 0) {
      setpopType(CONTENT_TYPE.SEARCH)
      setfilterSearchList(selectData)
    } else if (selectData.length === 0) {
      setpopType(CONTENT_TYPE.EMPTY)
      setfilterSearchList([])
    }
  }

  return {
    onInputChange,
    popType,
    filterSearchList,
    text,
    settext,
    setPopType: setpopType
  }
}

export default useInputChange
