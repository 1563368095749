import React from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react';

import { Tag } from '@components/Element/Tag';
import { store } from '@store/index';

import styles from './index.module.less';
export * from './const';

export type Status = 0 | 1 | 2 | 3 | 4 | 5 | 6;
// type TagConfigMap = {
//   [x in Status]: {
//     text: string;
//     type: string;
//     iconName: string;
//   };
// };
type TagConfigMap = Record<
  Status,
  {
    text: string;
    type: string;
    iconName: string;
  }
>;

interface KYCTagProps {
  // kycStep?: 1 | 2; // 1: kyc1,只展示kyc1状态 2: kyc2,只展示kyc2状态 ,不传值则展示整体状态
  className?: string;
  tagConfigMap: TagConfigMap;
}

export const KYCTag = observer((props: KYCTagProps) => {
  const { className, tagConfigMap } = props;
  const { certifiedStatus: kycStatus } = store.tradeStore.kycInfo;

  return (
    <Tag
      text={tagConfigMap[kycStatus]?.text}
      // textClass={styles.textSize}
      iconClass={styles.iconSize}
      iconName={tagConfigMap[kycStatus]?.iconName}
      className={cn(styles[tagConfigMap[kycStatus]?.type], className)}
    />
  );
});
